/** @format */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaRegWindowRestore, FaRegWindowMaximize } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Draggable from "react-draggable";

function ReadMe(props) {
  const [isMaximized, setIsMaximized] = useState(false);

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <Draggable handle='.title-bar'>
      <Modal
        show={props.show}
        onHide={handleClose}
        size={isMaximized ? "sm" : "md"}
        centered
        title='Buy'>
        <Modal.Header className=' title-bar px-0 py-2'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div className='title d-flex gap-2 p-2'>
              <img
                src='assets/notepadicon.webp'
                width={20}
                className='img-fluid'
                alt=''
              />
              <h6 className='mb-0'>readme.txt - Notepad</h6>
            </div>
            <button
              className='button maximize'
              onClick={handleMaximize}
              style={{
                marginRight: "6px",
                marginLeft: "auto",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}>
              {isMaximized ? (
                <a href='#!' className='popup-button expand text-white p-1'>
                  <FaRegWindowRestore size={16} />
                </a>
              ) : (
                <a href='#!' className='popup-button expand text-white p-1'>
                  <FaRegWindowMaximize size={16} />
                </a>
              )}
            </button>
            <button
              className='button close popup-button text-white'
              onClick={handleClose}
              style={{
                background: "red",
                cursor: "pointer",
              }}>
              <IoMdClose size={19} />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className='overflow-auto p-0' style={{ height: "60vh" }}>
          <div className=''>
            <ul
              className='nav navtab-header nav-pills mb-3'
              id='pills-tab'
              role='tablist'>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link active py-0 px-2'
                  id='pills-home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-home'
                  type='button'
                  role='tab'
                  aria-controls='pills-home'
                  aria-selected='true'>
                  File
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link py-0 px-2'
                  id='pills-profile-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-profile'
                  type='button'
                  role='tab'
                  aria-controls='pills-profile'
                  aria-selected='false'>
                  Edit
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link py-0 px-2'
                  id='pills-contact-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-contact'
                  type='button'
                  role='tab'
                  aria-controls='pills-contact'
                  aria-selected='false'>
                  Format
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link py-0 px-2'
                  id='pills-view-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-view'
                  type='button'
                  role='tab'
                  aria-controls='pills-view'
                  aria-selected='false'>
                  View
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link py-0 px-2'
                  id='pills-help-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-help'
                  type='button'
                  role='tab'
                  aria-controls='pills-help'
                  aria-selected='false'>
                  help
                </button>
              </li>
            </ul>
            <div className='tab-content' id='pills-tabContent'>
              <div
                className='tab-pane fade show active text-light px-3'
                id='pills-home'
                role='tabpanel'
                aria-labelledby='pills-home-tab '>
                <h3>community driven</h3>
                <p className='paragraph-2 text-light'>
                  4Chan 2.0 design. <br />
                  That's why it was launched as a fairlaunch
                </p>
                <h3>NO BULLSHIT roadmap</h3>
                <p className='paragraph-2 text-light'>
                  tax0%, No Bullshit, No Team tokens, LP tokens burned. It's that simple
                </p>
                <p>
                  {" "}
                  Supply: 420,690,000,000,000, Contract: Renounced Tax: 0%.
                </p>
                <h2>About</h2>
                <p>
                  In the early days of the internet, a titan was born: #4Chan.
                  For many, the connection sound of a simple HTML portal but a
                  symbol of the internet's birth. It represented the portal to
                  an unknown world, a world that promised the possibilities of
                  instant information, global communication, and a new form of
                  community. 4Chan soon became a pillar of this new world,
                  connecting friends, families, and strangers in terms of
                  sharing content like never before. The #4Chan2_0 coin was born
                  from the same spirit of innovation and connection that marked
                  #4Chan rise in the late early 2000s. Designed as a tribute to
                  #4Chan, this cryptocurrency is not just a digital asset but a
                  symbol of internet nostalgia. It’s a coin imbued with the
                  memory of the late-night chats, the excitement of hearing
                  "You've got Message!", and the joy of discovering a world
                  beyond your local community.
                </p>
              </div>
              <div
                className='tab-pane fade'
                id='pills-profile'
                role='tabpanel'
                aria-labelledby='pills-profile-tab'>
                edit
              </div>
              <div
                className='tab-pane fade'
                id='pills-contact'
                role='tabpanel'
                aria-labelledby='pills-contact-tab'>
                format
              </div>
              <div
                className='tab-pane fade'
                id='pills-view'
                role='tabpanel'
                aria-labelledby='pills-view-tab'>
                View
              </div>
              <div
                className='tab-pane fade'
                id='pills-help'
                role='tabpanel'
                aria-labelledby='pills-help-tab'>
                help
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Draggable>
  );
}

export default ReadMe;
