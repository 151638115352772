/** @format */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaRegWindowRestore, FaRegWindowMaximize } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Draggable from "react-draggable";

function InternetExplore(props) {
  const [isMaximized, setIsMaximized] = useState(false);

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <Draggable handle='.title-bar'>
      <Modal
        show={props.show}
        onHide={handleClose}
        size={isMaximized ? "xl" : "lg"}
        centered
        title='Buy'>
        <Modal.Header className='title-bar px-0 py-2'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div className='title d-flex gap-2 p-2'>
              <img
                src='assets/internetexplore.webp'
                width={20}
                className='img-fluid'
                alt=''
              />
              <h6 className='mb-0'>
              Uniswap - Microsoft Internet Explorer
              </h6>
            </div>
            <button
              className='button maximize'
              onClick={handleMaximize}
              style={{
                marginRight: "6px",
                marginLeft: "auto",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}>
              {isMaximized ? (
                <a href='#!' className='popup-button expand text-white p-1'>
                  <FaRegWindowRestore size={16} />
                </a>
              ) : (
                <a href='#!' className='popup-button expand text-white p-1'>
                  <FaRegWindowMaximize size={16} />
                </a>
              )}
            </button>
            <button
              className='button close popup-button text-white'
              onClick={handleClose}
              style={{
                background: "red",
                cursor: "pointer",
              }}>
              <IoMdClose size={19} />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body
          className='p-0 overflow-auto d-flex flex-column'
          style={{ height: "60vh", background: "#030303" }}>
          <div className='headerTitle-img'>
            <img src='assets/header.png' className='img-fluid' alt='' />
          </div>
          <div className='para text-center mt-0 pt-0 text-light'>
          
            <iframe
              src='https://app.uniswap.org/#/swap?outputCurrency=0x25d6d0feb66dbacdedf3d74fedc01b923c0092e0'
              width='100%'
              title='twitter'
              height={350}
              frameborder='0'></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </Draggable>
  );
}

export default InternetExplore;
