import React from "react";
import FooterMenu from "./FooterMenu";
import LeftSideMenu from "./LeftSideMenu";

const Base = (props) => {
  return (
    <>
      <div className='d-flex justify-content-between flex-column' style={{ height: '100vh' }}>
        <div className="layout-section d-flex gap-5 py-3">
          <div className="Sidebar-content">
            <LeftSideMenu />
          </div>
          <div className="main-section text-white d-none d-md-block">{props.children}</div>
        </div>
        <FooterMenu />
      </div>
    </>
  );
};

export default Base;
