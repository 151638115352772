import React, { useState } from "react";
import { Link } from "react-router-dom";
import RecycleBin from "./modals/RecycleBin";

const Hero = () => {

  
  const [showRecycleBin, setRecycleBin] = useState(false);

  return (
    <div className="Hero-section">
      <div className="container-fluid">
      
        <div className="position-relative">
        
          <Link to='/' className="recycle-bin" onClick={() => setRecycleBin(true)}>
          <img
              src="assets/recyclebin.webp"
              className="img-fluid "
              alt=""
              width={60}
            />
                <p>RecycleBin</p>
              </Link>
             
        </div>

        {showRecycleBin ? (
          <RecycleBin show={showRecycleBin} close={() => setRecycleBin(false)} />
        ) : (
          ""
        )}

      </div>
    </div>
  );
};

export default Hero;
