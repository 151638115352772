/** @format */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaRegWindowRestore, FaRegWindowMaximize } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Draggable from "react-draggable";

function ReadMeInner(props) {
  const [isMaximized, setIsMaximized] = useState(false);

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <Draggable handle='.title-bar'>
      <Modal
        show={props.show}
        onHide={handleClose}
        size={isMaximized ? "sm" : "md"}
        bottom
        title='Buy'>
        <Modal.Header className=' title-bar px-0 py-2'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div className='title d-flex gap-2 p-2'>
              <img
                src='assets/notepadicon.webp'
                width={20}
                className='img-fluid'
                alt=''
              />
              <h6 className='mb-0'>code.txt - Notepad</h6>
            </div>
            <button
              className='button maximize'
              onClick={handleMaximize}
              style={{
                marginRight: "6px",
                marginLeft: "auto",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}>
              {isMaximized ? (
                <a href='#!' className='popup-button expand text-white p-1'>
                  <FaRegWindowRestore size={16} />
                </a>
              ) : (
                <a href='#!' className='popup-button expand text-white p-1'>
                  <FaRegWindowMaximize size={16} />
                </a>
              )}
            </button>
            <button
              className='button close popup-button text-white'
              onClick={handleClose}
              style={{
                background: "red",
                cursor: "pointer",
              }}>
              <IoMdClose size={19} />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className='overflow-auto p-0' style={{ height: "60vh" }}>
          <div className=''>
            <ul
              className='nav navtab-header nav-pills mb-3'
              id='pills-tab'
              role='tablist'>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link active py-0 px-2'
                  id='pills-home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-home'
                  type='button'
                  role='tab'
                  aria-controls='pills-home'
                  aria-selected='true'>
                  File
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link py-0 px-2'
                  id='pills-profile-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-profile'
                  type='button'
                  role='tab'
                  aria-controls='pills-profile'
                  aria-selected='false'>
                  Edit
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link py-0 px-2'
                  id='pills-contact-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-contact'
                  type='button'
                  role='tab'
                  aria-controls='pills-contact'
                  aria-selected='false'>
                  Format
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link py-0 px-2'
                  id='pills-view-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-view'
                  type='button'
                  role='tab'
                  aria-controls='pills-view'
                  aria-selected='false'>
                  View
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link py-0 px-2'
                  id='pills-help-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-help'
                  type='button'
                  role='tab'
                  aria-controls='pills-help'
                  aria-selected='false'>
                  help
                </button>
              </li>
            </ul>
            <div className='tab-content' id='pills-tabContent'>
              <div
                className='tab-pane fade show active text-light'
                id='pills-home'
                role='tabpanel'
                aria-labelledby='pills-home-tab'>
                <p className='paragraph-2'>
                  Up, Up, Down, Down, Left, Right, Left, Right, B, A
                  <br />
                  <br />
                  Note: Memorize and delete this file!
                </p>
              </div>
              <div
                className='tab-pane fade'
                id='pills-profile'
                role='tabpanel'
                aria-labelledby='pills-profile-tab'>
                edit
              </div>
              <div
                className='tab-pane fade'
                id='pills-contact'
                role='tabpanel'
                aria-labelledby='pills-contact-tab'>
                format
              </div>
              <div
                className='tab-pane fade'
                id='pills-view'
                role='tabpanel'
                aria-labelledby='pills-view-tab'>
                View
              </div>
              <div
                className='tab-pane fade'
                id='pills-help'
                role='tabpanel'
                aria-labelledby='pills-help-tab'>
                help
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Draggable>
  );
}

export default ReadMeInner;
