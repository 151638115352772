import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaRegWindowRestore, FaRegWindowMaximize } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import ReadMeInner from "../innerModal/ReadMeInner";
import RoadMap from "../innerModal/RoadMap";
import IWant from "../innerModal/IWant";
import ShhhModal from "../innerModal/ShhhModal";

function RecycleBin(props) {
  const [isMaximized, setIsMaximized] = useState(false);
  const [showReadMeInner, setReadMeInner] = useState(false);
  const [showRoadMap, setRoadMap] = useState(false);
  const [showIWant, setIWant] = useState(false);
  const [showSpice, setSpice] = useState(false);
  const [showShhh, setShhh] = useState(false);

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <>
      <Draggable handle=".title-bar">
        <Modal
          show={props.show}
          onHide={handleClose}
          size={isMaximized ? "xl" : "lg"}
          centered
          title="Buy"
        >
          <Modal.Header className=" title-bar px-0 py-2">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="title d-flex gap-2 p-2">
                <img
                  src="assets/notepadicon.webp"
                  width={20}
                  className="img-fluid"
                  alt=""
                />
                <h6 className="mb-0">readme.txt - Notepad</h6>
              </div>
              <button
                className="button maximize"
                onClick={handleMaximize}
                style={{
                  marginRight: "6px",
                  marginLeft: "auto",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {isMaximized ? (
                  <a href="#!" className="popup-button expand text-white p-1">
                    <FaRegWindowRestore size={16} />
                  </a>
                ) : (
                  <a href="#!" className="popup-button expand text-white p-1">
                    <FaRegWindowMaximize size={16} />
                  </a>
                )}
              </button>
              <button
                className="button close popup-button text-white"
                onClick={handleClose}
                style={{
                  background: "red",
                  cursor: "pointer",
                }}
              >
                <IoMdClose size={19} />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body
            className="overflow-auto p-0 d-flex"
            style={{ height: "60vh", background: "#030303" }}
          >
            <div className="div">
              <div className="d-flex gap-2 RecycleBin-modal-section p-3">
                <div className="items">
                  <Link to="/" onClick={() => setReadMeInner(true)}>
                    <img
                      src="assets/notepadicon.webp"
                      className="img-fluid"
                      alt=""
                      width={64}
                    />
                    <p>code.txt</p>
                  </Link>
                </div>
                <div className="items">
                  <Link to="/" onClick={() => setRoadMap(true)}>
                    <img
                      src="assets/roadmap.webp"
                      className="img-fluid"
                      alt=""
                      width={64}
                    />
                    <p>road-map.jpg</p>
                  </Link>
                </div>
                <div className="items">
                  <Link to="/" onClick={() => setIWant(true)}>
                    <img
                      src="assets/iwantittahts.webp"
                      className="img-fluid"
                      alt=""
                      width={64}
                    />
                    <p>i want it that way.mp3</p>
                  </Link>
                </div>
                <div className="items">
                  <Link to="/" onClick={() => setSpice(true)}>
                    <img
                      src="assets/spice.webp"
                      className="img-fluid"
                      alt=""
                      width={64}
                    />
                    <p>spice girls.mpg</p>
                  </Link>
                </div>
                <div className="items">
                  <Link to="/" onClick={() => setShhh(true)}>
                    <img
                      src="assets/shhhh.webp"
                      className="img-fluid"
                      alt=""
                      width={64}
                    />
                    <p>Shh...</p>
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Draggable>
      {showReadMeInner ? (
        <ReadMeInner
          show={showReadMeInner}
          close={() => setReadMeInner(false)}
        />
      ) : (
        ""
      )}
      {showRoadMap ? (
        <RoadMap show={showRoadMap} close={() => setRoadMap(false)} />
      ) : (
        ""
      )}
      {showIWant ? (
        <IWant show={showIWant} close={() => setIWant(false)} />
      ) : (
        ""
      )}
      {showSpice ? (
        <IWant show={showSpice} close={() => setSpice(false)} />
      ) : (
        ""
      )}
      {showShhh ? (
        <ShhhModal show={showShhh} close={() => setShhh(false)} />
      ) : (
        ""
      )}
    </>
  );
}

export default RecycleBin;
