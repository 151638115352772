import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReadMe from "../../components/elements/modals/ReadMe";
import Aol from "../../components/elements/modals/Aol";
import Floppy from "../../components/elements/modals/Floppy";
import InternetExplore from "../../components/elements/modals/InternetExplore";
import AimModal from "../../components/elements/modals/AimModal";

const LeftSideMenu = () => {
  const [showReadMe, setReadMe] = useState(false);
  const [showAol, setAol] = useState(false);
  const [showFloppy, setFloppy] = useState(false);
  const [showExplorer, setExplorer] = useState(false);
  const [showAim, setAim] = useState(false);

  return (
    <div className="Sidebar-section">
      <div className="container-fluid p-0">
        <div className="menu-section">
          <ul className="p-0">
            <li>
              <Link to="/" onClick={() => setAol(true)}>
                <img src="assets/aol-mpg.webp" className="img-fluid" alt="" />
                <p>4Chan.mp4</p>
              </Link>
            </li>

            <li>
              <Link to="/" onClick={() => setReadMe(true)}>
                <img
                  src="assets/notepadicon.webp"
                  className="img-fluid"
                  alt=""
                />
                <p>readme.txt</p>
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => setFloppy(true)}>
                <img src="assets/floppy.webp" className="img-fluid" alt="" />
                <p>Floppy Disk Drive (A:)</p>
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => setExplorer(true)}>
                <img
                  src="assets/internetexplore.webp"
                  className="img-fluid"
                  alt=""
                />
                <p>Internet Explorer</p>
              </Link>
            </li>
            <li>
              <a href="https://etherscan.io/address/0x25d6d0feb66dbacdedf3d74fedc01b923c0092e0" target="_blank" rel="noreferrer">
                <img src="assets/etherscan-logo.svg" className="img-fluid" alt="" />
                <p>Contract</p>
              </a>
            </li>
            <li>
              <a  target="_blank" rel="noreferrer" href="https://www.dextools.io/app/en/ether/pair-explorer/0x4a6a149fd8b43d4842c7a7088002c3089254a941" >
                <img src="assets/dex.png" className="img-fluid" alt="" />
                <p>Chart</p>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/4Chan2ETH" target="_blank" rel="noreferrer">
                <img src="assets/twitter.webp" className="img-fluid" alt="" />
                <p>Twitter</p>
              </a>
            </li>
            <li>
              <a href="https://t.me/FourChan2ETH" target="_blank" rel="noreferrer">
                <img src="assets/telegram.webp" className="img-fluid" alt="" />
                <p>Telegram</p>
              </a>
            </li>
          </ul>
        </div>
        {showAol ? <Aol show={showAol} close={() => setAol(false)} /> : ""}
        {showFloppy ? (
          <Floppy show={showFloppy} close={() => setFloppy(false)} />
        ) : (
          ""
        )}

        {showReadMe ? (
          <ReadMe show={showReadMe} close={() => setReadMe(false)} />
        ) : (
          ""
        )}
        {showExplorer ? (
          <InternetExplore
            show={showExplorer}
            close={() => setExplorer(false)}
          />
        ) : (
          ""
        )}
        {showAim ? <AimModal show={showAim} close={() => setAim(false)} /> : ""}
      </div>
    </div>
  );
};

export default LeftSideMenu;
