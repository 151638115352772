import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaRegWindowRestore, FaRegWindowMaximize } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Draggable from "react-draggable";

function ShhhModal(props) {
  const [isMaximized, setIsMaximized] = useState(false);

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const handleClose = () => {
    props.close();
  };

  return (
    <Draggable handle=".title-bar">
      <Modal
        show={props.show}
        onHide={handleClose}
        size={isMaximized ? "sm" : "md"}
        bottom
        title="Buy"
      >
        <Modal.Header className=" title-bar px-0 py-2">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="title d-flex gap-2 p-2 text-light">
              <img
                src="assets/aim.webp"
                width={20}
                className="img-fluid"
                alt=""
              />
              <h6 className="mb-0">4Chan 2.0 - Instant Message</h6>
            </div>
            <button
              className="button maximize"
              onClick={handleMaximize}
              style={{
                marginRight: "6px",
                marginLeft: "auto",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              {isMaximized ? (
                <a href="#!" className="popup-button expand text-white p-1">
                  <FaRegWindowRestore size={16} />
                </a>
              ) : (
                <a href="#!" className="popup-button expand text-white p-1">
                  <FaRegWindowMaximize size={16} />
                </a>
              )}
            </button>
            <button
              className="button close popup-button text-white"
              onClick={handleClose}
              style={{
                background: "red",
                cursor: "pointer",
              }}
            >
              <IoMdClose size={19} />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body
          className="overflow-auto p-0 d-flex"
          style={{ height: "60vh", background: "#030303" }}
        >
          <div className="">
            <iframe
              srcDoc={`<blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="https://www.instagram.com/p/EXAMPLE/" data-instgrm-version="13"></blockquote><script async src="//www.instagram.com/embed.js"></script>`}
              style={{
                border: "0px #ffffff none",
                width: "100%",
                height: "100%",
              }}
              name="ifratest"
              scrolling="no"
              frameBorder="1"
              marginHeight="0px"
              marginWidth="0px"
              allowFullScreen
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </Draggable>
  );
}

export default ShhhModal;
