import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import InternetExplore from "../../components/elements/modals/InternetExplore";

const FooterMenu = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [showExplorer, setExplorer] = useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const timerID = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timerID);
  }, []);

  const formattedTime = currentTime.toLocaleString("en-US", {
    hour: "numeric",
  minute: "2-digit",
  hour12: true
  });

  return (
    <div className="FooterMenu-section p-0 d-flex align-items-center gap-3 justify-content-between">
      <div class="menu-side-wrapper">
        <div>
          <Link to="/" class="start-button" onClick={handleClick}>
            <img
              src="assets/windows-start-button.png"
              width={145}
              className="img-fluid"
              alt=""
            />
          </Link>
          {isOpen && (
            <div className="drop-up-menu">
              <div className="header-start">
                <img
                  src="assets/administration.webp"
                  className="img-fluid"
                  width={25}
                  alt=""
                />
                <h6>Administrator</h6>
              </div>
              <ul className="p-0">
                <li>
                  <Link to="/" className="d-flex gap-2 align-items-center">
                    <img
                      src="assets/Doom-Icon.webp"
                      className="img-fluid"
                      width={25}
                      alt=""
                    />
                    <p>DOOM</p>
                  </Link>
                </li>
                <li>
                  <Link to="/" className="d-flex gap-2 align-items-center">
                    <img
                      src="assets/SmarterChatIcon.webp"
                      className="img-fluid"
                      width={25}
                      alt=""
                    />
                    <p>SmaterChild</p>
                  </Link>
                </li>
                <li>
                  <Link to="/" className="d-flex gap-2 align-items-center" onClick={() => setExplorer(true)}>
                    <img
                      src="assets/internetexplore.webp"
                      className="img-fluid"
                      width={25}
                      alt=""
                    />
                    <p>Internet Explore</p>
                  </Link>
                 </li>
                <li>
                  <a href="https://twitter.com/4Chan2ETH" rel="noreferrer" target="_blank" className="d-flex gap-2 align-items-center">
                    <img
                      src="assets/twitter.webp"
                      className="img-fluid"
                      width={25}
                      alt=""
                    />
                    <p>Twitter</p>
                  </a>
                </li>
                <li>
                  <a href="https://t.me/FourChan2ETH" rel="noreferrer" target="_blank" className="d-flex gap-2 align-items-center">
                    <img
                      src="assets/telegram.webp"
                      className="img-fluid"
                      width={25}
                      alt=""
                    />
                    <p>TeleGram</p>
                  </a>
                </li>
              </ul>
              <Link className="text-decoration-none text-black footer-start d-flex align-items-center justify-content-center py-4">
                <img
                  src="	assets/windows-shutdown-icon-7.jpg"
                  className="img-fluid"
                  width={35}
                  alt=""
                />
                <p>Shut Down</p>
                <IoMdArrowDropright className="fs-4" />
              </Link>
            </div>
          )}

{showExplorer ? (
          <InternetExplore
            show={showExplorer}
            close={() => setExplorer(false)}
          />
        ) : (
          ""
        )}
        </div>
      </div>

      <div className="timer-div d-flex gap-3 align-items-center fs-4">
        <p className="mb-0">{formattedTime}</p>
      </div>
    </div>
  );
};

export default FooterMenu;
