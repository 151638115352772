/** @format */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaRegWindowRestore, FaRegWindowMaximize } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Draggable from "react-draggable";

function Floppy(props) {
  const [isMaximized, setIsMaximized] = useState(false);

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  const handleClose = () => {
    props.close();
  };

  const handleOk = () => {
    handleClose();
  };

  return (
    <Draggable handle='.title-bar'>
      <Modal
        show={props.show}
        onHide={handleClose}
        size={isMaximized ? "sm" : "md"}
        centered
        title='Buy'>
        <Modal.Header className=' title-bar px-0 py-2'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <div className='title d-flex gap-2 p-2'>
              <img
                src='assets/notepadicon.webp'
                width={20}
                className='img-fluid'
                alt=''
              />
              <h6 className='mb-0'>readme.txt - Notepad</h6>
            </div>
            <button
              className='button maximize'
              onClick={handleMaximize}
              style={{
                marginRight: "6px",
                marginLeft: "auto",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}>
              {isMaximized ? (
                <a href='#!' className='popup-button expand text-white p-1'>
                  <FaRegWindowRestore size={16} />
                </a>
              ) : (
                <a href='#!' className='popup-button expand text-white p-1'>
                  <FaRegWindowMaximize size={16} />
                </a>
              )}
            </button>
            <button
              className='button close popup-button text-white'
              onClick={handleClose}
              style={{
                background: "red",
                cursor: "pointer",
              }}>
              <IoMdClose size={19} />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body
          className='overflow-auto p-0 d-flex align-items-center justify-content-center'
          style={{ height: "60vh", background: "#030303" }}>
          <div className='para text-center text-light'>
            <p>A:\ is not accessible.</p>
            <p>
              The request could not be performed because of an I/O device error.
            </p>
            <button className='btn btn-ok px-4' onClick={handleOk}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Draggable>
  );
}

export default Floppy;
