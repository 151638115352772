
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import RoutesPage from './pages/routesPage/RoutesPage.jsx'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-277117541-1');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <div className="App">
      <RoutesPage />
    </div>
  );
}

export default App;
